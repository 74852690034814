import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { pedidos } from '@/utils/pedidos'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePedidosList() {
  // Use toast
  const toast = useToast()

  const refPedidosListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'is_urgent', label: 'Prioridad', sortable: true },
    { key: 'codigo', label: 'Código', sortable: true },
    { key: 'fecha', label: 'Fecha', sortable: true },
    { key: 'fk_user_creado', label: 'Creado por', sortable: true },
    { key: 'estado', label: 'Estado', sortable: true },
    { key: 'descripcion_custom', label: 'Descripción', sortable: true },
    { key: 'actions', label: 'Acciones', thStyle: { minWidth: '150px' } },
  ]
  const perPage = ref(50)
  const totalPedidos = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPedidosListTable.value ? refPedidosListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPedidos.value,
    }
  })

  const refetchData = () => {
    refPedidosListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchPedidos = (ctx, callback) => {
    store
      .dispatch('app-pedidos/fetchPedidos', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
          user_created: store.state.auth.user.fk_role !== 1 ? store.state.auth.user.id : null, // query when is not admin
        },
        headers: {
          'X-Sort': `pedidos.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalPedidos.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching pedidos list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const parseDate = date => {
    let d = date.substring(0, 10)
    d = d.split('-').reverse().join('-')
    const t = date.substring(12, 19)
    return `${d} ${t}`
  }

  const resolveStatusVariant = status => {
    const item = pedidos.estados.find(el => el.value === status)
    if (item) return item.variant
    return '-'
  }

  const resolveStatusText = status => {
    const item = pedidos.estados.find(el => el.value === status)
    if (item) return item.label
    return '-'
  }

  return {
    fetchPedidos,
    tableColumns,
    perPage,
    currentPage,
    totalPedidos,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPedidosListTable,

    refetchData,
    parseDate,
    resolveStatusVariant,
    resolveStatusText,

    // Extra Filters
    statusFilter,
  }
}
